import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { HeaderHistoryTools, HistoryToolsSmallScreen } from "@shared/features/DesignTools";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useInlineCropping } from "@internal/feature-inline-cropping";
import { useToastManager } from "@internal/feature-toasts";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { getPanelsForHistoryAction } from "../helpers";
import * as styles from "./HistoryToolbar.module.scss";

const message = defineMessages({
    activePanelChangedByHistoryToast: {
        id: "studio.components.Toast.activePanelChangedByHistoryToast",
        defaultMessage:
            "Your last action affected a different design area. We've switched your view to this design area for you to review the changes.",
        description: "Alert that pops up when the active canvas was changed by undo/redo"
    },
    panelsModifiedByHistoryToast: {
        id: "studio.components.Toast.panelsModifiedByHistoryToast",
        defaultMessage:
            "Your last action affected items in design areas that are currently not visible. Please review the changes to ensure they are as intended.",
        description: "Alert that pops up when items not on the currently active canvas were changed"
    }
});

export const HistoryToolbar = observer(() => {
    const designEngine = useDesignEngine();
    const { canRedo, canUndo } = designEngine.historyStore;
    const { isSmall } = useStudioLayout();
    const cropping = useInlineCropping();

    const cropActive = useMemo(() => cropping.isInlineCroppingAvailable && cropping.cropInfo.isCropActive, [cropping]);
    const undoDisabled = useMemo(() => !canUndo || cropActive, [canUndo, cropActive]);
    const redoDisabled = useMemo(() => !canRedo || cropActive, [canRedo, cropActive]);
    const toastManager = useToastManager();
    const { t } = useTranslationSSR();

    const processPanelsForHistoryAction = (panels: PanelState[]) => {
        if (panels.length && !panels.some(panel => panel.id === designEngine.idaStore.activeDesignPanelId)) {
            designEngine.layoutStore.setVisiblePanelIds([panels[0].id]);
            designEngine.idaStore.setActiveDesignPanelId(panels[0].id);
            toastManager.resetToasts();
            toastManager.pushToast({
                skin: "warning",
                autoClose: false,
                content: t(message.activePanelChangedByHistoryToast.id, {
                    panel: panels[0].panelProperties.name
                })
            });
        } else if (panels.length > 1) {
            toastManager.resetToasts();
            toastManager.pushToast({
                skin: "warning",
                autoClose: false,
                content: t(message.panelsModifiedByHistoryToast.id, {
                    panels: panels.map(panel => panel.panelProperties.name).join(", ")
                })
            });
        }
    };

    const undo = () => {
        const panels = getPanelsForHistoryAction(designEngine.historyStore, designEngine.cimDocStore, "undo");

        designEngine.historyStore.undo();

        processPanelsForHistoryAction(panels);
    };

    const redo = () => {
        const panels = getPanelsForHistoryAction(designEngine.historyStore, designEngine.cimDocStore, "redo");

        designEngine.historyStore.redo();

        processPanelsForHistoryAction(panels);
    };

    // the nested ternary will be removed after the AB test for history tools in the header
    // eslint-disable-next-line no-nested-ternary
    return isSmall ? (
        <HistoryToolsSmallScreen
            undo={undo}
            redo={redo}
            canRedo={!redoDisabled}
            canUndo={!undoDisabled}
            transitionIn={!undoDisabled || !redoDisabled}
            marginLeft={"3"}
            marginTop={"4"}
            className={styles.smallScreenHistoryToolbar}
        />
    ) : (
        <HeaderHistoryTools undo={undo} redo={redo} canRedo={!redoDisabled} canUndo={!undoDisabled} />
    );
});
